<script setup>
  const props = defineProps({
    width: {
      type: Number,
      default(rawProps) {
        return 250;
      },
    },
  });

  const theme = useTheme();
  const logo = ref('/img/logo_with_tagline.svg');

  watchEffect(() => {
    if (theme.name.value === 'dark') {
      logo.value = '/img/logo_with_tagline_white.svg';
    } else {
      logo.value = '/img/logo_with_tagline.svg';
    }
  });
</script>

<template>
  <v-img
    v-if="theme.name && logo"
    :src="logo"
    :width="width"
    alt="stokkd logo"
    class="pr-[2px]"
  />
</template>
